import { Layout, Space } from 'antd';
import React from 'react';
import Break from '../../Break';

const { Footer } = Layout;
const currentYear = new Date().getFullYear();

export default function FooterNav() {
	return (
		<>
			<Footer style={{ textAlign: 'center', borderTop: '#34b87d solid 1px' }}>
				<Space size={8}>
					© {currentYear} Created by Alpine IQ Inc.
					<a href="https://support.alpineiq.com/terms-and-conditions">Terms &amp; conditions</a>
					<Break
						type="vertical"
						e="0"
						w="0"
					/>
					<a href="https://alpineiq.com/privacy-policy">Privacy policy</a>
					<Break
						type="vertical"
						e="0"
						w="0"
					/>
					<a href="https://support.alpineiq.com/acceptable-use-policy">Acceptable use policy</a>
					<Break
						type="vertical"
						e="0"
						w="0"
					/>
					<a href="https://lab.alpineiq.com/trackingPreferences/1034">Data preferences</a>
				</Space>
			</Footer>
		</>
	);
}
